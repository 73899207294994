import React, { useCallback, useEffect, useState } from 'react';
import _, { noop } from 'lodash';

import { PaperSlidingLayout } from 'ui/components/Paper/PaperSlidingLayout';
import { Page } from 'ui/components/Page/Page';
import { Pagination } from 'services/search';

import { ChannelDetailsCard, ChannelTable } from './components';
import { initialPagination } from './consts';
import { MyChannelsPageCmp } from './types';
import { Routes } from '../../navigation';
import { Channel, getChannels } from 'services/commerce/channels';
import { logErrorCtx } from 'app/logging';
import { GlobalSettingsBanner } from '../../components/GlobalSettingsBanner';
import {
  fetchSellwareActiveAccount,
  getCommerce,
  getCommerceAccountIsConfigured,
  getCommerceAccountIsConnected,
  getCommerceAccountIsDisconnected,
  getCommerceAccountIsOrWasConnected,
} from 'services/commerce';
import { useDispatch, useSelector } from 'react-redux';
import { getActiveUser } from 'services/user';
import { getSellwareUrl } from '../DiscoverChannels/helpers';
import { ReconnectBanner } from '../../components/ReconnectBanner';
import { useUrlQueryObject } from 'services/url';
import { getSettingsCompany } from 'services/settings/company';
import { useFlags } from 'helpers/useFlags';
import { useLocation, useHistory } from 'react-router-dom';
import { showNotification } from 'services/api';
import {
  register,
  reconnect,
  getCommerceTenantInfo,
  registerSellwareViaIdentityService,
  registerViaCommerceIntegrations,
  reconnectViaCommerceIntegrations,
} from 'services/commerce/api';
import { CreateAccountModal } from '../DiscoverChannels/components/CreateAccountModal';
import { DiscoverChannelsHeroComponent } from '../DiscoverChannels/components/DiscoverChannelsHeroComponent';
import { ReconnectingModal } from '../DiscoverChannels/components/ReconnectingModal';
import { SuccessModal } from '../DiscoverChannels/components/SuccessModal';
import { CommerceSettingsModal } from './components/CommerceSettingsModal';
import ModalActionsProvider from './components/CommerceSettingsModal/ModalActionsProvider';
import { ConfirmationModal } from 'ui/components/Modal/ConfirmationModal';
import { Typography } from '@mui/material';
import { useAuth0 } from '@auth0/auth0-react';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const MyChannelsPage: MyChannelsPageCmp = () => {
  const [channels, setChannels] = useState<Channel[]>([]);
  const [activeId, setActiveId] = useState<number | null>(null);
  const [pagination, setPagination] = useState<Pagination>(initialPagination);
  const [loadingChannels, setLoadingChannels] = useState(false);
  const [loadingCommerce, setLoadingCommerce] = useState(true);
  const [commerceInitialized, setCommerceInitialized] = useState(false);
  const [sellwareUrlForReconnectFlow, setSellwareUrlForReconnectFlow] =
    useState<string>('');
  const [createAccountModalOpen, setCreateAccountModalOpen] = useState(false);
  const [successModalOpen, setSuccessModalOpen] = useState(false);
  const [reconnectingModalOpen, setReconnectingModalOpen] = useState(false);
  const [sellwareUrlForRegisterFlow, setSellwareUrlForRegisterFlow] =
    useState<string>('');
  const [showCommerceSettingsModal, setShowCommerceSettingsModal] =
    useState(false);
  const [commerceClientInfo, setCommerceClientInfo] = useState<{
    clientId: number | null;
    productIds: string[];
    registeredApps: string[];
  } | null>(null);
  const [isRegisteringWithChannelManager, setIsRegisteringWithChannelManager] =
    useState(false);

  const [, extendUrlQuery] = useUrlQueryObject();

  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const queryParams = new URLSearchParams(location.search);

  const flags = useFlags();

  const activeUser = useSelector(getActiveUser);

  useEffect(() => {
    const getCommerceInfo = async () => {
      if (activeUser?.user?.email) {
        const data = await getCommerceTenantInfo(activeUser.user.email);
        setCommerceClientInfo(data);
      }
    };
    getCommerceInfo();
  }, [activeUser?.user?.email]);

  const userHasPurchasedChannelManager =
    commerceClientInfo &&
    commerceClientInfo.productIds?.indexOf('commerce') > -1;

  const userHasRegisteredChannelManager =
    commerceClientInfo &&
    commerceClientInfo.registeredApps?.indexOf('commerce') > -1;

  const commerce = useSelector(getCommerce);

  const isSellwareConfigured =
    useSelector(getCommerceAccountIsConfigured) &&
    userHasPurchasedChannelManager &&
    userHasRegisteredChannelManager;

  const isSellwareDisconnected =
    useSelector(getCommerceAccountIsDisconnected) &&
    userHasPurchasedChannelManager &&
    userHasRegisteredChannelManager;

  const isSellwareReadyToRegister =
    useSelector(getCommerceAccountIsDisconnected) &&
    userHasPurchasedChannelManager &&
    !userHasRegisteredChannelManager;

  const isSellwareConnected = useSelector(getCommerceAccountIsConnected);

  const companySettings = useSelector(getSettingsCompany);
  const companyName = _.get(companySettings, 'name', '');

  const isOrWasConnectedToSellware =
    useSelector(getCommerceAccountIsOrWasConnected) &&
    userHasPurchasedChannelManager &&
    userHasRegisteredChannelManager;

  const showReconnectBanner =
    isSellwareDisconnected && isOrWasConnectedToSellware;

  const showGlobalSettingsBanner =
    !isSellwareConfigured && isOrWasConnectedToSellware && !showReconnectBanner;

  useEffect(() => {
    const tempActiveId = queryParams?.get('activeId');
    if (tempActiveId) {
      setActiveId(Number(tempActiveId));
    } else {
      setActiveId(null);
    }
  }, [queryParams]);

  // reorder data when pagination sort changed
  useEffect(() => {
    setChannels(
      _.orderBy(channels, pagination.sort.sortBy, pagination.sort.direction)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination.sort]);

  const getMyChannels = useCallback(async () => {
    setLoadingChannels(true);

    try {
      const res = await getChannels();
      setChannels(
        _.orderBy(res, pagination.sort.sortBy, pagination.sort.direction)
      );
    } catch (e) {
      logErrorCtx('Error getting Commerce Channels', {
        error: e as Error,
        component: 'MyChannelsPage',
      });
    }

    setLoadingChannels(false);
  }, [pagination]);

  useEffect(() => {
    if (isSellwareConnected) {
      getMyChannels();
    }
  }, [isSellwareConnected]);

  const handleClose = useCallback(() => {
    extendUrlQuery({ activeId: null });
  }, [extendUrlQuery]);

  useEffect(() => {
    const registrationUrl =
      (flags.commerceAuth0Enabled
        ? process.env.REACT_APP_COMMERCE_REGISTER_URL
        : process.env.REACT_APP_SELLWARE_REGISTER_URL) || '';

    const getSellwareUrlForRegisterFlow = async () => {
      const url = await getSellwareUrl(
        activeUser,
        companyName,
        registrationUrl,
        true,
        false,
        false
      );
      setSellwareUrlForRegisterFlow(url);
    };

    const getSellwareUrlForReconnectFlow = async () => {
      const url = await getSellwareUrl(
        activeUser,
        companyName,
        registrationUrl,
        false,
        true,
        false
      );
      setSellwareUrlForReconnectFlow(url);
    };

    if (
      (isSellwareDisconnected || isSellwareReadyToRegister) &&
      activeUser &&
      companyName
    ) {
      getSellwareUrlForReconnectFlow();
    } else if (!isOrWasConnectedToSellware && activeUser && companyName) {
      getSellwareUrlForRegisterFlow();
    }
  }, [activeUser, showReconnectBanner, companyName, flags]);

  useEffect(() => {
    const callRegistration = async () => {
      if (location.search && !flags.driveAuth0Enabled) {
        const queryParams = new URLSearchParams(location.search);

        const appId = queryParams?.get('appId');
        const apiSecret = queryParams?.get('apiSecret');
        const tenantId = queryParams?.get('tenantId');
        const registerFlow = queryParams?.get('register');
        const refreshToken = queryParams?.get('refreshToken');
        const accessToken = queryParams?.get('accessToken');

        if (tenantId && registerFlow) {
          history.replace({
            search: '',
          });
          setCreateAccountModalOpen(true);

          try {
            await register(
              tenantId,
              appId,
              apiSecret,
              accessToken,
              refreshToken
            );

            setSuccessModalOpen(true);
            setCreateAccountModalOpen(false);

            setTimeout(() => {
              //@ts-ignore
              dispatch(fetchSellwareActiveAccount());
            }, 1000);
          } catch (e) {
            setCreateAccountModalOpen(false);
            showNotification(
              'There was an error connecting to Fishbowl Commerce. Try again later.',
              {
                variant: 'error',
              }
            );
          }
        }
      }
    };

    if (location.search) {
      callRegistration();
    }
  }, []);

  useEffect(() => {
    const callRegistration = async () => {
      if (location.search && flags.driveAuth0Enabled) {
        const queryParams = new URLSearchParams(location.search);

        const tenantId = queryParams?.get('tenantId');
        const registerSSO = queryParams?.get('registerSSO');
        const refreshToken = queryParams?.get('refreshToken');
        const accessToken = queryParams?.get('accessToken');

        if (tenantId && registerSSO) {
          history.replace({
            search: '',
          });

          try {
            await registerViaCommerceIntegrations(
              tenantId,
              accessToken,
              refreshToken
            );

            setTimeout(() => {
              //@ts-ignore
              dispatch(fetchSellwareActiveAccount());
            }, 100);
          } catch (e) {
            setCreateAccountModalOpen(false);
            showNotification(
              'There was an error connecting to Fishbowl Commerce. Try again later.',
              {
                variant: 'error',
              }
            );
          }
        }
      }
    };

    if (location.search) {
      callRegistration();
    }
  }, [location.search, flags.commerceAuth0Enabled]);

  const { getAccessTokenSilently } = useAuth0();

  const callRegisterViaIdentityService = useCallback(async () => {
    const clientId = commerceClientInfo?.clientId;
    const firstName = activeUser?.user?.firstName;
    const lastName = activeUser?.user?.lastName;
    const email = activeUser?.user?.email;

    const accessToken = await getAccessTokenSilently();

    const registrationUrl =
      (flags.commerceAuth0Enabled
        ? process.env.REACT_APP_COMMERCE_REGISTER_URL
        : process.env.REACT_APP_SELLWARE_REGISTER_URL) || '';

    if (
      clientId &&
      firstName &&
      lastName &&
      email &&
      companyName &&
      accessToken
    ) {
      setIsRegisteringWithChannelManager(true);

      try {
        await registerSellwareViaIdentityService(
          clientId,
          firstName,
          lastName,
          email,
          companyName,
          accessToken
        );

        let url = '';

        const getSellwareUrlForRegisterFlow = async () => {
          url = await getSellwareUrl(
            activeUser,
            companyName,
            registrationUrl,
            false,
            false,
            true
          );
        };

        await getSellwareUrlForRegisterFlow();

        window.location.href = url;
      } catch (e) {
        setIsRegisteringWithChannelManager(false);
        showNotification(
          'There was an error connecting to Channel Manager. Try again later.',
          {
            variant: 'error',
          }
        );
      }
    }
  }, [commerceClientInfo, activeUser, companyName]);

  const callReconnectViaIdentityService = useCallback(async () => {
    const clientId = commerceClientInfo?.clientId;
    const firstName = activeUser?.user?.firstName;
    const lastName = activeUser?.user?.lastName;
    const email = activeUser?.user?.email;

    const accessToken = await getAccessTokenSilently();

    const reconnectionUrl =
      (flags.commerceAuth0Enabled
        ? process.env.REACT_APP_COMMERCE_REGISTER_URL
        : process.env.REACT_APP_SELLWARE_REGISTER_URL) || '';

    if (
      clientId &&
      firstName &&
      lastName &&
      email &&
      companyName &&
      accessToken
    ) {
      let url = '';

      const getSellwareUrlForReconnectFlow = async () => {
        url = await getSellwareUrl(
          activeUser,
          companyName,
          reconnectionUrl,
          false,
          false,
          false,
          true
        );
      };

      await getSellwareUrlForReconnectFlow();

      window.location.href = url;
    }
  }, [commerceClientInfo, activeUser, companyName]);

  useEffect(() => {
    const callReconnect = async () => {
      const queryParams = new URLSearchParams(location.search);

      const appId = queryParams?.get('appId');
      const apiSecret = queryParams?.get('apiSecret');
      const tenantId = queryParams?.get('tenantId');
      const refreshToken = queryParams?.get('refreshToken');
      const accessToken = queryParams?.get('accessToken');
      const reconnectFlow = queryParams?.get('reconnect');

      if (tenantId && appId && apiSecret && reconnectFlow) {
        history.replace({
          search: '',
        });
        setReconnectingModalOpen(true);

        try {
          await reconnect(
            tenantId,
            appId,
            apiSecret,
            accessToken,
            refreshToken
          );

          setReconnectingModalOpen(false);

          setTimeout(() => {
            // @ts-ignore
            dispatch(fetchSellwareActiveAccount());
          }, 1000);

          showNotification(
            'Your Fishbowl Commerce account has been reconnected.',
            {
              variant: 'success',
            }
          );
        } catch (e) {
          setReconnectingModalOpen(false);

          showNotification(
            'There was an error reconnecting to Fishbowl Commerce. Try again later.',
            {
              variant: 'error',
            }
          );
        }
      }
    };

    if (location.search && !flags.driveAuth0Enabled) {
      callReconnect();
    }
  }, []);

  useEffect(() => {
    const callReconnect = async () => {
      const queryParams = new URLSearchParams(location.search);

      const tenantId = queryParams?.get('tenantId');
      const reconnectSSO = queryParams?.get('reconnectSSO');
      const refreshToken = queryParams?.get('refreshToken');
      const accessToken = queryParams?.get('accessToken');

      if (tenantId && reconnectSSO) {
        history.replace({
          search: '',
        });

        try {
          await reconnectViaCommerceIntegrations(
            tenantId,
            accessToken,
            refreshToken
          );

          setTimeout(() => {
            //@ts-ignore
            dispatch(fetchSellwareActiveAccount());
          }, 100);
        } catch (e) {
          setCreateAccountModalOpen(false);
          showNotification(
            'There was an error connecting to Channel Manager. Try again later.',
            {
              variant: 'error',
            }
          );
        }
      }
    };
    if (location.search && flags.driveAuth0Enabled) {
      callReconnect();
    }
  }, []);

  const handleCloseSuccessModal = () => {
    setSuccessModalOpen(false);
  };

  useEffect(() => {
    const registrationUrl =
      (flags.commerceAuth0Enabled
        ? process.env.REACT_APP_COMMERCE_REGISTER_URL
        : process.env.REACT_APP_SELLWARE_REGISTER_URL) || '';
    const getSellwareUrlForReconnectFlow = async () => {
      const url = await getSellwareUrl(
        activeUser,
        companyName,
        registrationUrl,
        false,
        true
      );
      setSellwareUrlForReconnectFlow(url);
    };

    if (showReconnectBanner && activeUser && companyName) {
      getSellwareUrlForReconnectFlow();
    }
  }, [activeUser, companyName, showReconnectBanner]);

  const handleChannelClick = useCallback(
    async (channelId: number) => {
      extendUrlQuery({ activeId: channelId });
    },
    [extendUrlQuery]
  );

  const handleOpenSettingsModal = useCallback(() => {
    setShowCommerceSettingsModal(true);
  }, [showCommerceSettingsModal]);

  const handleCloseSettingsModal = useCallback(() => {
    setShowCommerceSettingsModal(false);
  }, [showCommerceSettingsModal]);

  // This is all to prevent the flicker showing the wrong component during load.
  useEffect(() => {
    const hasConnection = commerce?.connection !== '';
    const status = commerce?._status || {};
    const isLoading = status.isLoading === true;
    const isError = status.isError === true;
    const isStatusUndefined = commerce?._status === undefined;

    // If there's an active connection, mark commerce as initialized
    if (hasConnection) {
      setCommerceInitialized(true);
      setLoadingCommerce(false);
    }

    // If an error occurred and it's not loading, stop loading
    if (isError && !isLoading) {
      setCommerceInitialized(true);
      setLoadingCommerce(false);
      return;
    }

    // If commerce is not initialized yet
    if (!commerceInitialized) {
      if (isLoading) {
        // If it's loading, set initialized
        setCommerceInitialized(true);
      }
      // If not loading, wait for the next update
      return;
    }

    // If status is undefined or not loading and no connection, stop loading
    if (isStatusUndefined || (!isLoading && !hasConnection)) {
      setLoadingCommerce(false);
    }
  }, [commerce]);

  const ConfirmationModalBody = (
    <span>
      <Typography variant="body1">
        <b>Please leave this tab open.</b>
      </Typography>
      <Typography variant="body1" sx={{ marginTop: '16px' }}>
        This will take a few minutes. Afterwards you will be able to configure
        your Global Settings and then manage your Commerce Channels.
      </Typography>
    </span>
  );

  return (
    <>
      {showReconnectBanner && (
        <ReconnectBanner
          sellwareUrl={sellwareUrlForReconnectFlow}
          handleReconnectClick={callReconnectViaIdentityService}
        />
      )}
      {showGlobalSettingsBanner && (
        <GlobalSettingsBanner
          handleCallToActionClick={handleOpenSettingsModal}
        />
      )}

      {!loadingCommerce && !isSellwareConnected && (
        <>
          <DiscoverChannelsHeroComponent
            sellwareUrl={sellwareUrlForRegisterFlow}
            handleRegisterWithSellware={callRegisterViaIdentityService}
          />
          <CreateAccountModal open={createAccountModalOpen} />
          <SuccessModal
            open={successModalOpen}
            handleClose={handleCloseSuccessModal}
          />
          <ReconnectingModal open={reconnectingModalOpen} />
          <ConfirmationModal
            open={isRegisteringWithChannelManager}
            title={'We’re getting your Channel Manager set up!'}
            body={ConfirmationModalBody}
            onConfirmClicked={_.noop}
            onCancelClicked={_.noop}
            confirmLabel=""
            cancelLabel=""
            DialogActionsComponent={() => null}
          />
        </>
      )}

      {isSellwareConnected ? (
        <ModalActionsProvider closeModal={handleCloseSettingsModal}>
          <Page>
            <PaperSlidingLayout shown={Boolean(activeId)}>
              <ChannelTable
                items={channels}
                activeItemId={activeId}
                handleItemClick={handleChannelClick}
                handleOpenSettingsModal={handleOpenSettingsModal}
                isLoadingItems={loadingChannels}
                pagination={pagination}
                onPaginationChange={setPagination}
              />
              <ChannelDetailsCard
                activeItemId={activeId}
                channels={channels}
                onClose={handleClose}
                fetchChannels={getMyChannels}
              />
            </PaperSlidingLayout>
          </Page>
          <CommerceSettingsModal
            visible={showCommerceSettingsModal}
            open={showCommerceSettingsModal}
            onClose={handleCloseSettingsModal}
            onApplyClicked={() => noop()}
          />
        </ModalActionsProvider>
      ) : null}
    </>
  );
};

MyChannelsPage.route = Routes.MyChannelsPage;

export default MyChannelsPage;
