import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { getCommerceAccountIsOrWasConnected } from 'services/commerce/redux/selectors';
import { getCommerceTenantInfo } from 'services/commerce/api';
import { SellwareIntegrations, IconNames } from 'ui/theme';
import { useDiscoverChannelsHeroComponent } from './styled';
import FBOButton from 'ui/theme/components/FBOButton/FBOButton';
import { fishbowlCommerceLogo } from '../../consts';
import { getActiveUser } from 'services/user';
import { useFlags } from 'helpers/useFlags';

const DiscoverChannelsHeroComponent = (props: any) => {
  const { sellwareUrl, handleRegisterWithSellware } = props;
  const activeUser = useSelector(getActiveUser);
  const [commerceClientInfo, setCommerceClientInfo] = useState<{
    clientId: number | null;
    productIds: string[];
    registeredApps: string[];
  } | null>(null);

  const classes = useDiscoverChannelsHeroComponent();

  const driveAuth0Enabled = useFlags()?.driveAuth0Enabled;

  useEffect(() => {
    const getCommerceInfo = async () => {
      if (activeUser?.user?.email) {
        const data = await getCommerceTenantInfo(activeUser.user.email);
        setCommerceClientInfo(data);
      }
    };
    getCommerceInfo().then();
  }, [activeUser?.user?.email]);

  const userHasPurchasedChannelManager =
    commerceClientInfo &&
    commerceClientInfo.productIds?.indexOf('commerce') > -1;

  const userHasRegisteredChannelManager =
    commerceClientInfo &&
    commerceClientInfo.registeredApps?.indexOf('commerce') > -1;

  const isOrWasConnectedToSellware =
    useSelector(getCommerceAccountIsOrWasConnected) &&
    userHasPurchasedChannelManager &&
    userHasRegisteredChannelManager;

  const showConnectToSellwareButton =
    !isOrWasConnectedToSellware && userHasPurchasedChannelManager;

  const showContactManagerButton =
    !isOrWasConnectedToSellware && !userHasPurchasedChannelManager;

  return (
    <Box className={classes.container}>
      <img
        src={fishbowlCommerceLogo}
        width="200px"
        alt="Fishbowl Commerce logo"
      />
      <Typography align="left" variant="h3">
        Discover Channels
      </Typography>
      {isOrWasConnectedToSellware ? (
        <Typography component="span" align="left">
          Connect new channels to your existing Fishbowl Commerce account via
          Fishbowl today to expand your reach and take your business to new
          levels of success!
          <ul>
            <li>
              Sell more, without the hassle of managing multiple platforms
              separately by integrating additional ecommerce channels
            </li>
            <li>
              Sync your new channels with Fishbowl Commerce via Fishbowl to
              process orders lightning-fast and delight customers with speedy
              deliveries
            </li>
            <li>Stay ahead of the curve by adding new channels as needed</li>
            <li>
              Maintain control and consistency across all channels from a
              centralized hub while easily managing inventory, preventing
              stockouts and overselling
            </li>
          </ul>
          Click “Add New Channels” to get started today!
        </Typography>
      ) : (
        <Typography component="span" align="left">
          Ready to take your online business to the next level? Connect your
          ecommerce channels with Fishbowl Commerce to turbo charge your
          ecommerce operations, reach a wider audience, and become more
          profitable!
          <ul>
            <li>
              Sync inventory, orders, and product listings automatically to keep
              your data up-to-date in real-time
            </li>
            <li>
              Consolidate your ecommerce channels to Increase visibility, sales,
              and customer satisfaction with every order fulfilled
            </li>
            <li>
              Maintain control and consistency across all channels from a
              centralized hub
            </li>
            <li>
              Efficiently manage inventory levels and prevent overselling
              nightmares
            </li>
          </ul>
          Connect to Fishbowl Commerce to get started today!
        </Typography>
      )}
      <img
        src={SellwareIntegrations}
        alt="Sellware integrations logos"
        className={classes.integrationsImage}
      />

      {!commerceClientInfo && (
        <FBOButton
          loading={true}
          color={'positive'}
          variant={'primary'}
          data-qa={'loading'}
        />
      )}
      {commerceClientInfo &&
        showConnectToSellwareButton &&
        !driveAuth0Enabled && (
          <FBOButton
            variant="primary"
            color="positive"
            size="medium"
            data-qa="connect-to-sellware-button"
            icon={IconNames.IconAvatar}
            href={sellwareUrl}
            target="_blank"
            linkStyledAsButton
          >
            Click Here To Get Started
          </FBOButton>
        )}

      {commerceClientInfo &&
        showConnectToSellwareButton &&
        driveAuth0Enabled && (
          <FBOButton
            variant="primary"
            color="positive"
            size="medium"
            data-qa="connect-to-sellware-button"
            icon={IconNames.IconAvatar}
            onClick={handleRegisterWithSellware}
          >
            Click Here To Get Started
          </FBOButton>
        )}

      {commerceClientInfo && showContactManagerButton && (
        <FBOButton
          variant="primary"
          color="positive"
          size="medium"
          data-qa="connect-to-sellware-button"
          icon={IconNames.IconAvatar}
          href={'https://help.fishbowlonline.com/'}
          target="_blank"
          linkStyledAsButton
        >
          Contact Your Account Manager To Get Started
        </FBOButton>
      )}
    </Box>
  );
};

export default DiscoverChannelsHeroComponent;
